.body {
    background-color: #000000 !important;
    background-image: none;
}

.Component-main-1 {
    background: none !important;
}

#login-screen {
    position: absolute;
    top: 45%;
    margin-top: -200px;
    /* half of #content height*/
    left: 0;
    width: 100%;
}

.login-area {
    background-color: #000000;
    border: 2px solid #ffffff;
    -ms-border-radius: 20px;
    border-radius: 20px;
    width: 350px;
    margin: auto;
}

.login-area {
    padding: 20px 10px 0px 10px;
}

.login-area form label {
    color: #ffffff;
    font-style: normal;
    font-weight: 100;
}

.agxio-form-control {
    -ms-border-radius: 5px;
    border-radius: 5px;
    height: 30px;
    width: 100%;
    padding: 5px;
}

.logo-container {
    height: 180px;
    /*can be anything*/
    position: relative;
    margin-bottom: 30px;
}

.logo-container img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 50px;
}

.login-button {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 5px;
    text-decoration: none;
    color: #ffffff;
    width: 100%;
    text-align: center;
    height: 30px;
}

:disabled {
    color: grey;
    border-color: grey;
}

.agxio-footer {
    color: #000000;
    font-size: 2vh;
}

.form-group {
    margin-bottom: 10px;
    padding: 5px;
    color: #ffffff;
    font-weight: bold;
}