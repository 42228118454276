@font-face {
  font-family: FuturaPT;
  src: url("./FuturaPTBold.otf") format("opentype");
  font-weight: bolder;
}
@font-face {
  font-family: FuturaPT;
  src: url("./FuturaPTHeavy.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: FuturaPT;
  src: url("./FuturaPTMedium.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: FuturaPT;
  src: url("./FuturaPTLight.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: FuturaPT;
  src: url("./FuturaPTBook.otf") format("opentype");
  font-weight: normal;
}

* {
  font-family: FuturaPT;
}
